import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'

import { isShopifyEnabled } from '@featureflags'
import Client from 'shopify-buy'

const STORE_URL = process.env.GATSBY_SHOPIFY_STORE_URL_ALIAS
const ACCESS_TOKEN = process.env.GATSBY_STOREFRONT_ACCESS_TOKEN
const SHOPIFY_ENABLED = isShopifyEnabled()

const createShopifyClient = () => {
	if (!STORE_URL || !ACCESS_TOKEN) {
		throw new Error('Missing Shopify Store URL or Access Token')
	}

	const client = Client.buildClient({
		domain: STORE_URL,
		storefrontAccessToken: ACCESS_TOKEN,
	})

	return client
}

export const shopifyClient = createShopifyClient()

const initialCartState = {
	enabled: SHOPIFY_ENABLED,
	id: '',
	webUrl: '',
	completedAt: '',
	checkoutUrl: '',
	subtotalPrice: '',
	lineItems: [],
	lineItemCount: 0,
	isAdding: false,
	isUpdating: false,
	isRemoving: false,
	images: {},
	paths: {},
}

const CartContext = createContext(null)

const shopifyCheckoutId = 'shopify_checkout_id'
const shopifyMetadata = 'shopifyMetadata'

const setMetadataInLocalStorage = (data) => {
	try {
		localStorage.setItem(shopifyMetadata, JSON.stringify(data))
	} catch {}
}

export const CartProvider = ({ children }) => {
	const [cart, setCart] = useState(initialCartState)
	const initializedCartRef = useRef(false)
	const [isOpen, setIsOpen] = useState(false)

	const closeCart = useCallback(() => setIsOpen(false), [])
	const openCart = useCallback(() => setIsOpen(SHOPIFY_ENABLED), [])

	useEffect(() => {
		if (!SHOPIFY_ENABLED) return

		if (initializedCartRef.current) return
		initializedCartRef.current = true

		const initializeCart = async () => {
			const checkoutID = localStorage.getItem(shopifyCheckoutId)

			let images = {}
			try {
				images = JSON.parse(localStorage.getItem(shopifyMetadata) || {})
			} catch {}

			const setCheckoutId = (checkoutId) => {
				localStorage.setItem(shopifyCheckoutId, checkoutId)
			}

			if (!checkoutID) {
				const newCheckout = await shopifyClient.checkout.create()
				setCheckoutId(newCheckout.id.toString())
				setCart((prevCart) => ({ ...prevCart, ...newCheckout, images }))
				return
			}

			try {
				const existingCheckout = await shopifyClient.checkout.fetch(checkoutID)

				if (existingCheckout.completedAt) {
					setCheckoutId('')
					setCart(initialCartState)
					return
				}

				setCart((prevCart) => ({ ...prevCart, ...existingCheckout, images }))
			} catch (error) {
				localStorage.setItem(shopifyCheckoutId, '')
			}
		}

		initializeCart()
	}, [])

	return (
		<CartContext.Provider value={{ cart, setCart, isOpen, openCart, closeCart, client: shopifyClient }}>
			{children}
		</CartContext.Provider>
	)
}

export const useCart = () => {
	const context = useContext(CartContext)

	if (context === undefined || context === null) {
		throw new Error('useCart must be used within a CartProvider')
	}

	return context
}

const TWO_SECONDS = 2000

export const useAddItem = () => {
	const { cart, openCart, setCart } = useCart()
	const addItem = async ({ variantId, quantity, image, fullPath }) => {
		if (!SHOPIFY_ENABLED) return

		if (quantity <= 0) return

		try {
			setCart((prevState) => ({ ...prevState, isAdding: true }))
			const { id } = cart
			const newCart = await shopifyClient.checkout.addLineItems(id, [{ variantId, quantity }])
			setCart((prevState) => {
				const images = { ...prevState.images, [variantId]: image }
				const paths = { ...prevState.paths, [variantId]: fullPath }
				setMetadataInLocalStorage({ images, paths })
				return { ...prevState, ...newCart, isAdding: false, images, paths }
			})
			openCart()
		} catch (error) {
			console.error('error', error)
			setTimeout(() => {
				setCart((prevState) => ({ ...prevState, isAdding: false }))
			}, TWO_SECONDS)
		}
	}

	return addItem
}

export const useRemoveItem = () => {
	const { cart, setCart } = useCart()

	const removeItem = async ({ product, lineItemId }) => {
		if (!SHOPIFY_ENABLED) return

		try {
			setCart((prevState) => ({ ...prevState, isRemoving: true }))
			const { id } = cart

			const newCart = await shopifyClient.checkout.removeLineItems(id, [lineItemId])
			setCart((prevState) => ({ ...prevState, ...newCart, isRemoving: false }))
		} catch (error) {
			console.error(error)
			setTimeout(() => {
				setCart((prevState) => ({ ...prevState, isRemoving: false }))
			}, TWO_SECONDS)
		}

		if (window && window.dataLayer) {
			window.dataLayer.push({
				event: 'remove_from_cart',
				item_id: product?.id || 'ITEM ID',
				item_name: product?.title || 'ITEM NAME',
				currency: cart?.currencyCode || 'CURRENCY CODE',
				quantity: product?.quantity || 'ITEM QUANTITY',
			})
		}
	}

	return removeItem
}

export const useUpdateItem = () => {
	const { cart, setCart } = useCart()

	const updateItem = async ({ variantId, quantity }) => {
		if (!SHOPIFY_ENABLED) return

		if (quantity <= 0) return
		try {
			setCart((prevState) => ({ ...prevState, isUpdating: true }))
			const { id } = cart
			const newCart = await shopifyClient.checkout.updateLineItems(id, [{ id: variantId, quantity }])
			setCart((prevState) => ({ ...prevState, ...newCart, isUpdating: false }))
		} catch (error) {
			console.error(error)
			setTimeout(() => {
				setCart((prevState) => ({ ...prevState, isUpdating: false }))
			}, TWO_SECONDS)
		}
	}

	return updateItem
}

export const useFetchProduct = (shopifyId) => {
	const [product, setProduct] = useState()
	const [loading, setLoading] = useState(SHOPIFY_ENABLED && !!shopifyId)
	const [error, setError] = useState()

	useEffect(() => {
		if (!SHOPIFY_ENABLED) return
		const fetch = async () => {
			try {
				setLoading(true)
				const product = await shopifyClient.product.fetch(shopifyId)
				setProduct(product)
				setLoading(false)
			} catch (error) {
				setError(error)
			}
		}
		if (shopifyId) fetch()
		else {
			console.warn('No shopifyId provided')
		}
	}, [shopifyId])

	return { product, loading, error }
}

export const fetchInventory = async (shopifyGid) => {
	const response = await fetch(`https://vmi4a4jhug.execute-api.us-east-2.amazonaws.com/default/shopifyInventory`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ shopifyGid }),
	})

	const json = await response.json()
	return json.data?.product
}
